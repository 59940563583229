<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Translado </v-card-subtitle>
    <v-card-text>
      <v-row class="mb-1">
        <v-col cols="12">
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <AreaSelect
                      :rules="reglas.idAreaOrigen"
                      :label="'Area Origen'"
                      :clearable="true"
                      v-model="datos.idAreaOrigen"
                      :dense="true"
                      :outlined="true"
                      :padre="store.state.sucursalSelect.id"
                    >
                    </AreaSelect>
                  </v-col>
                  <v-col cols="12">
                    <SelectSucursal
                      :rules="reglas.requerido"
                      :label="'Sucursal Destino'"
                      v-model="datos.idSucursalDestino"
                      :dense="true"
                      :outlined="true"
                    ></SelectSucursal>
                  </v-col>
                  <v-col cols="12">
                    <AreaSelect
                      :padre="datos.idSucursalDestino"
                      :rules="reglas.idAreaDestino"
                      :label="'Area Destino'"
                      :clearable="true"
                      v-model="datos.idAreaDestino"
                      :dense="true"
                      :outlined="true"
                    >
                    </AreaSelect>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <date-picker
                      class="mb-0"
                      :rules="reglas.fechaTranslado"
                      v-model="datos.fechaTranslado"
                      :label="`Fecha Translado`"
                      :dense="true"
                      :outlined="true"
                    ></date-picker>

                    <v-textarea
                      class="mt-0"
                      rows="4"
                      v-model="datos.observacion"
                      outlined
                      label="Observacion"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-divider> </v-divider>
    </v-card-text>
    <v-card-text>
      <v-row class="mb-1">
        <v-col>
          <v-card-subtitle>Detalles</v-card-subtitle>
        </v-col>

        <v-col class="text-right">
          <v-btn class="ml-2" @click="abrirModalBusquedaProductoA()" small color="primary">Busqueda avanzada</v-btn>
        </v-col>
        <v-col>
          <BuscardorProductoBarra
            :indInventario="false"
            :idArea="datos.idAreaOrigen"
            @input="GetProducto"
            ref="BuscardorProductoBarraRef"
          ></BuscardorProductoBarra>
        </v-col>
      </v-row>

      <v-simple-table fixed-header height="300px" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Producto</th>
              <th class="text-left">Lote</th>
              <th class="text-left">Unidad Medida</th>
              <th class="text-right">Existencia Actual</th>

              <th class="text-right">Cantidad</th>
              <th></th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in datos.transladoDetalle" :key="i">
              <td @click="editar(item)">
                <small> {{ item.producto.producto.barra }} </small>
                <br />
                {{ item.producto.producto.nombre }}
              </td>
              <td @click="editar(item)">
                <small v-if="item.producto.lote">
                  {{ FuncionesGenerales.camelCase(item.producto.lote.numeroLote) }}
                </small>
              </td>
              <td @click="editar(item)">
                {{ item.producto.producto.unidadMedida.nombre }}
              </td>
              <td class="text-right" @click="editar(item)">
                <span v-if="item.producto.lote">{{ item.producto.lote.existenciaLote.inventario }}</span>
                <span v-else>{{ item.producto.existencia.inventario }}</span>
              </td>

              <td class="text-right" @click="editar(item)">
                {{ item.cantidad.toFixed(2) }}
              </td>

              <td class="text-center">
                <v-btn @click="eliminarItem(item)" color="error" icon x-small>
                  <v-icon>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text class="text-right mb-2">
      <v-btn small color="secondary mr-2" @click="limpiar()" :disabled="guardando"> Limpiar </v-btn>
      <v-btn small color="primary mr-2" @click="guardar()" :loading="guardando"> Guardar </v-btn>
    </v-card-text>
    <ModalBusquedaProductoAvanzado
      @GetProductos="GetProductos"
      :columnas="['ID', 'NOMBRE', 'CODIGO', 'EXISTENCIA']"
      :fullscreen="true"
      :soloExistencia="false"
      :IndExistencia="false"
      ref="ModalBusquedaProductoAvanzadoRef"
      :idArea="datos.idAreaOrigen"
    >
    </ModalBusquedaProductoAvanzado>

    <ModalEditarProducto ref="ModalEditarProductoRef"></ModalEditarProducto>
    <ModalFinalizar :estatus="estatus" @aceptar="guardarDB" ref="ModalFinalizarRef"></ModalFinalizar>
  </v-card>
</template>
 
<script>
import { ref } from '@vue/composition-api'
import AreaSelect from '../../../archivo/area/componentes/AreaSelect.vue'
import store from '@/store'
import DatePicker from '@/components/DatePicker.vue'
import SelectProductoSucursal from '@/views/sistema/producto/catalogo/producto/componentes/SelectProductoSucursal.vue'
import Lote from '../../../lote/Lote.vue'
import { mdiCached, mdiDelete } from '@mdi/js'
import FuncionesGenerales from '@/funciones/funciones'
import BuscardorProductoBarra from '@/views/sistema/producto/catalogo/producto/componentes/BuscardorProductoBarra.vue'
import ModalBusquedaProductoAvanzado from '@/views/sistema/producto/catalogo/producto/componentes/ModalBusquedaProductoAvanzado.vue'
import ModalEditarProducto from './ModalEditarProducto.vue'
import ModalFinalizar from './ModalFinalizar.vue'
import TransladoServices from '@/api/servicios/TransladoServices'
import SelectSucursal from '../../../../configuracion/sucursal/componentes/Select.vue'
export default {
  components: {
    AreaSelect,
    DatePicker,
    SelectProductoSucursal,
    Lote,
    BuscardorProductoBarra,
    ModalBusquedaProductoAvanzado,
    ModalEditarProducto,
    ModalFinalizar,
    SelectSucursal,
  },
 
  setup(props, context) {
    const guardando = ref(false)
    const ModalBusquedaProductoAvanzadoRef = ref()
    const ModalEditarProductoRef = ref(null)
    const form = ref()
    const ModalFinalizarRef = ref(null)
    const transladoInit = {
      id: -1 /*int*/,
      idSucursalOrigen: store.state.sucursalSelect.id,
      idSucursalDestino: store.state.sucursalSelect.id,
      idAreaOrigen: 0 /*int*/,
      idAreaDestino: 0 /*int*/,
      idEstatus: 0 /*int*/,
      fechaTranslado: null /*DateTime*/,
      observacion: '' /*string*/,
      totalCantidades: 0 /*decimal*/,
      idUsuarioCrea: 0 /*int*/,
      idUsuarioModifica: 0 /*int*/,
      fechaCreacion: null /*DateTime*/,
      fechaModificacion: null /*DateTime*/,
      transladoDetalle: [],
      areaOrigen: {},
      areaDestino: {},
      estatus: {},
    }
    const estatus = [
      {
        id: 18,
        descripcion: 'Borrador',
      },
      {
        id: 19,
        descripcion: 'Pendiente Por enviar',
      },
      {
        id: 20,
        descripcion: 'Enviar',
      },
    ]
    /*
    18	6	Borrador
    19	6	Pendiente
    20	6	Enviado
    21	6	Rechazado
    28	6	Recibido
    29	6	Finalizado
    30	6	Anulado
    */
    const transladoDetalleInit = {
      id: 0 /*int*/,
      idTranslado: 0 /*int*/,
      idProducto: 0 /*int*/,
      idProductoSucursal: 0 /*int*/,
      idEstatus: 0 /*int*/,
      existenciaActualOrigen: 0 /*decimal*/,
      existenciaActualDestino: 0 /*decimal*/,
      cantidad: 0 /*decimal*/,
      idUsuarioCrea: 0 /*int*/,
      idUsuarioModifica: 0 /*int*/,
      fechaCreacion: null /*DateTime*/,
      fechaModificacion: null /*DateTime*/,
      translado: {},
      producto: {},
      estatus: {},
    }
    const reglas = {
      idAreaOrigen: [v => !!v || 'Es requerido'],
      requerido: [v => !!v || 'Es requerido'],
      idAreaDestino: [
        v => !!v || 'Es requerido',
        v => v != datos.value.idAreaOrigen || 'Debe ser diferencte a la area origen',
      ],
      fechaTranslado: [v => !!v || 'Es requerido'],
    }
    const datos = ref({ ...transladoInit })
    const abrirModalBusquedaProductoA = () => {
      ModalBusquedaProductoAvanzadoRef.value.abrirModal(true)
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    let identity = -10000
    const identityGlobal = () => {
      identity = identity + 1
      return identity
    }
    const GetProducto = datosIn => {
      //alert('GetProducto')
      console.log(datosIn)
      if (datosIn.id != undefined) {
        let existeProducto = false
        if (
          datos.value.transladoDetalle.find(
            it => it.idProductoSucursal == datosIn.id && (datosIn.lote == null || it.idLote == datosIn.lote[0].id),
          )
        ) {
          existeProducto = true
        } else {
          existeProducto = false
        }

        if (!existeProducto) {
          datos.value.transladoDetalle.push({
            id: identityGlobal() /*int*/,
            idProductoSucursal: datosIn.id /*int*/,
            idProducto: datosIn.idProducto,
            idLote: datosIn.lote.length > 0 ? datosIn.lote[0].id : null,
            idEstatus: 1 /*int*/,
            cantidad: 0 /*decimal*/,
            producto: { ...datosIn, lote: datosIn.lote[0] },
            existenciaActualOrigen:
              datosIn.lote.length > 0
                ? datosIn.lote[0].existenciaLote.inventario
                : datosIn.existencia.inventario /*decimal*/,
            existenciaActualDestino: 0 /*decimal*/,
          })
        }
      }
    }
    const GetProductos = datosIn => {
      console.log(datosIn)

      datosIn.forEach(element => {
        let existeProducto = false
        if (
          datos.value.transladoDetalle.find(
            it => it.idProductoSucursal == element.id && (element.lote == null || it.idLote == element.lote.id),
          )
        ) {
          existeProducto = true
        } else {
          existeProducto = false
        }

        if (!existeProducto) {
          datos.value.transladoDetalle.push({
            id: identityGlobal() /*int*/,
            idProductoSucursal: element.id /*int*/,
            idProducto: element.idProducto,
            idLote: element.lote ? element.lote.id : null,
            idEstatus: 1 /*int*/,
            cantidad: 0 /*decimal*/,
            producto: element,
            existenciaActualOrigen: element.lote
              ? element.lote.existenciaLote.inventario
              : element.existencia.inventario /*decimal*/,
            existenciaActualDestino: 0 /*decimal*/,
          })
        }
      })
    }
    const indLote = item => {
      let val = false
      if (item?.producto?.indLote == true) {
        val = true
      }
      return val
    }
    const indLoteSelect = item => {
      let val = false
      if (item?.lote?.id > 0) {
        val = true
      }
      return val
    }

    const GetLote = dato => {
      datos.value.lote = dato
    }
    const UnidadMedida = dato => {
      let val = {
        id: 1,
        abreviatura: 'U',
        nombre: 'Unidad',
      }
      if (dato?.producto?.unidadMedida.id > 0) {
        val = dato?.producto?.unidadMedida
      }

      return val
    }
    const GetListar = item => {
      context.emit('GetListar', item)
    }
    const guardarDB = estatus => {
      if (estatus.id != undefined) {
        datos.value.idEstatus = estatus.id
        console.log(datos.value)

        guardando.value = true
        TransladoServices.Actualizar(store.state.user.idUsuario, datos.value)
          .then(response => {
            if (response.data.estatus == true) {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
              GetListar(response.data.datos.datos)
              limpiar()
            } else {
              store.commit('setAlert', {
                message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                type: 'warning',
              })
            }
          })
          .catch(err => {
            store.commit('setAlert', {
              message: err,
              type: 'error',
            })
          })
          .finally(() => {
            guardando.value = false
          })
      } else {
        store.commit('setAlert', {
          message: `error al seleccionar el estatus`,
          type: 'warning',
        })
      }
    }
    const editar = item => {
      ModalEditarProductoRef.value.abrir(item)
    }
    const guardar = () => {
      if (validarForm()) {
        console.log('datos.value.transladoDetalle')

        if (datos.value.transladoDetalle.length > 0) {
          if (!datos.value.transladoDetalle.find(item => item.cantidad <= 0)) {
            ModalFinalizarRef.value.abrir()
          } else {
            store.commit('setAlert', {
              message: 'Los productos no pueden tener cantidad menor o igual 0',
              type: 'info',
            })
          }
        } else {
          store.commit('setAlert', {
            message: 'Debe agregar por lo menos un producto',
            type: 'info',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'verifique todos los los datos',
          type: 'info',
        })
      }
    }
    const limpiar = () => {
      datos.value = { ...transladoInit }
      datos.value.transladoDetalle = []
    }

    return {
      datos,
      AreaSelect,
      store,
      indLote,
      GetLote,
      indLoteSelect,
      icons: {
        mdiCached,
        mdiDelete,
      },
      FuncionesGenerales,
      UnidadMedida,
      GetProductos,
      GetProducto,
      ModalBusquedaProductoAvanzadoRef,
      abrirModalBusquedaProductoA,
      ModalEditarProductoRef,
      editar,
      form,
      validarForm,
      reglas,
      guardar,
      limpiar,
      guardarDB,
      ModalFinalizarRef,
      guardando,
      estatus,
    }
  },
}
</script>