<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <ViewTranslado
        :VerButton="VerButton"
        @GetCerrarModal="GetCerrarModal"
        @GetAnular="GetAnular"
        @GetEditar="GetEditar"
        @GetPdf="GetPdf"
        @GetFinalizar="GetFinalizar"
        @GetEnviar = "GetEnviar"
        @GetRecibir = "GetRecibir"
        :IdDocumento="IdDocumento"
        :modal="dialog"
      ></ViewTranslado>
    </v-dialog>
  </v-row>
</template>
<script>
import { ref } from '@vue/composition-api'
import ViewTranslado from './ViewTranslado.vue'
export default {
  components: {
    ViewTranslado,
  },
  props: {
    VerButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const dialog = ref(false)
    const IdDocumento = ref(null)
    const abrir = IdDocumentoin => {
      IdDocumento.value = IdDocumentoin
      dialog.value = true
    }
    const GetCerrarModal = () => {
      dialog.value = false
    }
    const GetAnular = item => {
      context.emit('GetAnular', item)
      dialog.value = false
    }
    const GetRecibir = item => {
      context.emit('GetRecibir', item)
      dialog.value = false
    }
    const GetEnviar = item => {
      context.emit('GetEnviar', item)
      dialog.value = false
    }
    const GetEditar = item => {
      context.emit('GetEditar', item)
      dialog.value = false
    }
    const GetPdf = item => {
      context.emit('GetPdf', item)
    }
    const GetFinalizar = item => {
      context.emit('GetFinalizar', item)
      dialog.value = false
    }

    return {
      dialog,
      abrir,
      IdDocumento,
      GetCerrarModal,
      GetAnular,
      GetEditar,
      GetPdf,
      GetFinalizar,
      GetRecibir,
      GetEnviar
    }
  },
}
</script>
