<template>
  <v-card>
    <v-card-title>
      <span>Translado </span>
      <v-spacer></v-spacer>
      <v-btn v-if="modal == true" icon @click="cerrarModal()">
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="cargando" class="text-center">
      <h4>Buscando Translado ...</h4>
    </v-card-text>
    <v-card-text v-if="!cargando && datos">
      <v-row>
        <v-col lg="8" md="8" sm="12" cols="12">
          <CabeceraEmpresa></CabeceraEmpresa>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <h2 class="pt-12 pb-2">
            #
            <small>{{ datos.numeroDocumento }} </small>
          </h2>
          <span class="m-0 p-0"> Fecha creacion: {{ FuncionesGenerales.formatoFecha(datos.fechaCreacion, 1) }} </span>
          <br />
          <span class="m-0 p-0"> Hora creacion: {{ FuncionesGenerales.formatoFecha(datos.fechaCreacion, 2) }} </span>
        
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-4 mb-2">
        <v-col lg="8" md="8" sm="12" cols="12">
          <span class="m-0 p-0"> Sucursal Origen: {{ datos.sucursalOrigen.nombre }}</span>
          <br />
          <span class="m-0 p-0"> Sucursal Destino: {{ datos.sucursalDestino.nombre }}</span>
          <br />
          <h4 class="m-0 p-0">Estatus: {{ datos.estatus.descripcion }}</h4>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <span class="m-0 p-0"> Fecha Translado: {{ FuncionesGenerales.formatoFecha(datos.fechaTranslado, 1) }} </span>
          <span class="m-0 p-0"> Area Origen: {{ datos.areaOrigen.nombre }}</span>
          <br />
          <span class="m-0 p-0"> Area Destino: {{ datos.areaDestino.nombre }}</span>
          <br />
          <h4 class="m-0 p-0">Estatus: {{ datos.estatus.descripcion }}</h4>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Producto</th>
                  <th class="text-left">Lote</th>
                  <th class="text-right">Cantidad</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in datos.transladoDetalle" :key="i">
                  <td>
                    <small> {{ item.producto.barra }} </small>
                    <br />
                    {{ item.producto.nombre }}
                  </td>
                  <td>
                    <span v-if="item.lote">{{ item.lote.numeroLote }}</span>
                  </td>
                  <td class="text-right">
                    {{ item.cantidad }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" sm="6" md="8" lg="8"> </v-col>
      </v-row>

      <v-divider></v-divider>
    </v-card-text>
    <v-card-actions v-if="!cargando && datos">
      <v-spacer></v-spacer>

      <v-btn class="secondary" x-small @click="show = !show">
        Ver Historial <v-icon x-small>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text v-if="!cargando && datos">
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>

          <v-card-text>
            <v-timeline align-top dense>
              <v-timeline-item color="primary" small v-for="(item,index) in datos.transladoEstatus" :key="index">
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>{{FuncionesGenerales.formatoFecha(item.fecha,1) }}</strong> <br>
                    <small>{{FuncionesGenerales.formatoFecha(item.fecha,2) }}</small>
                  </v-col>
                  <v-col cols="9">
                    <strong>{{item.estatus.descripcion}}</strong>
                    <div class="text-caption">{{item.usuario.empleado.nombre}} {{item.usuario.empleado.apellido}}</div>
                  </v-col>
                </v-row>
              </v-timeline-item>

           
            </v-timeline>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card-text>
    <v-card-actions v-if="!cargando && datos">
      <v-row>
        <v-col cols="12" class="text-center mt-5">
          <BtnPdf :id="IdDocumento" :tipoBtn="1"></BtnPdf>
          <v-btn
            v-if="VerButton == true && (datos.idEstatus == 18)"
            color="primary"
            class="ml-2"
            small
            :disabled="!(datos.idEstatus == 18)"
            @click="GetEditar(datos)"
          >
            <v-icon left small>
              {{ icons.mdiPencil }}
            </v-icon>
            Editar
          </v-btn>
          <v-btn
            v-if="VerButton == true && (datos.idEstatus == 18 || datos.idEstatus == 19)"
            color="primary"
            class="ml-2"
            small
            :disabled="!(datos.idEstatus == 18 || datos.idEstatus == 19)"
            @click="GetEnviar(datos)"
          >
            <v-icon left small>
              {{ icons.mdiCheck }}
            </v-icon>
            Enviar
          </v-btn>
          <v-btn
            v-if="VerButton == true && (datos.idEstatus == 20 && store.state.sucursalSelect.id == datos.idSucursalDestino)"
            color="success"
            class="ml-2"
            small
            :disabled="!(datos.idEstatus == 20 && store.state.sucursalSelect.id == datos.idSucursalDestino)"
            @click="GetRecibir(datos)"
          >
            <v-icon left small>
              {{ icons.mdiCheck }}
            </v-icon>
            Recibir
          </v-btn>
          <v-btn
            v-if="VerButton == true && (
                (datos.idEstatus == 20 || datos.idEstatus == 28) &&
                store.state.sucursalSelect.id == datos.idSucursalDestino
              )"
            color="primary"
            class="ml-2"
            small
            :disabled="
              !(
                (datos.idEstatus == 20 || datos.idEstatus == 28) &&
                store.state.sucursalSelect.id == datos.idSucursalDestino
              )
            "
            @click="GetFinalizar(datos)"
          >
            <v-icon left small>
              {{ icons.mdiCheck }}
            </v-icon>
            Finalizar
          </v-btn>
          <v-btn
            v-if="VerButton == true && !(datos.idEstatus == 30 || store.state.sucursalSelect.id != datos.idSucursalOrigen)"
            color="error"
            class="ml-2"
            small
            :disabled="(datos.idEstatus == 30 || store.state.sucursalSelect.id != datos.idSucursalOrigen)"
            @click="GetAnular(datos)"
          >
            <v-icon left small> {{ icons.mdiBlockHelper }} </v-icon>Anular
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import BtnPdf from './BtnPdf.vue'
import store from '@/store'
import {
  mdiClose,
  mdiPrinter,
  mdiPencil,
  mdiCheck,
  mdiBlockHelper,
  mdiFilePdfBox,
  mdiChevronDown,
  mdiChevronUp,
} from '@mdi/js'
import TransladoServices from '@/api/servicios/TransladoServices'
export default {
  components: {
    CabeceraEmpresa,
    BtnPdf,
  },
  props: {
    IdDocumento: Number,
    modal: Boolean,
    VerButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const show = ref(false)
    const cargando = ref(false)
    const datos = ref({})
    onBeforeMount(() => {
      cargar()
    })
    watch(props, () => {
      cargar()
    })

    const cerrarModal = () => {
      context.emit('GetCerrarModal')
    }
    const cargar = () => {
      cargando.value = true
      TransladoServices.ConsultarDetalles({ id: props.IdDocumento })
        .then(response => {
          if ((response.data.estatus = true)) {
            if (response.data.datos != null && response.data.datos.length > 0) {
              console.log('ConsultarDetalles')
              console.log(response.data.datos)
              datos.value = response.data.datos[0]
            } else {
              store.commit('setAlert', {
                message: 'No hay resultado en la busqueda',
                type: 'warning',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: err,
            type: 'error',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const GetAnular = item => {
      context.emit('GetAnular', item)
    }
    const GetEditar = item => {
      context.emit('GetEditar', item)
    }
    const GetPdf = item => {
      context.emit('GetPdf', item)
    }
    const GetEnviar = item => {
      context.emit('GetEnviar', item)
    }
    const GetRecibir = item => {
      context.emit('GetRecibir', item)
    }
    const GetFinalizar = item => {
      context.emit('GetFinalizar', item)
    }

    return {
      datos,
      FuncionesGenerales,
      cerrarModal,
      cargar,
      icons: {
        mdiClose,
        mdiPrinter,
        mdiPencil,
        mdiCheck,
        mdiBlockHelper,
        mdiFilePdfBox,
        mdiChevronDown,
        mdiChevronUp,
      },
      GetAnular,
      GetEditar,
      GetPdf,
      GetFinalizar,
      GetRecibir,
      GetEnviar,
      cargando,
      store,
      show,
    }
  },
}
</script>