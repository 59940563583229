
<template>
  <div>
    <v-card>
      <v-card-subtitle
        >Lista

        <v-btn icon @click="cargar()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
          <v-icon>{{ icons.mdiCached }}</v-icon>
        </v-btn>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" offset-md="8" md="4">
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Buscar"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="Lista"
        :search="search"
        :items-per-page="5"
        :loading="cargandoDatos"
        class="table-kitchen-sink"
      >
        <template #[`item.fechaAjuste`]="{ item }">
          {{ FuncionesGenerales.formatoFecha(item.fechaAjuste, 1) }}
        </template>
        <template #[`item.fechaCreacion`]="{ item }">
          {{ FuncionesGenerales.formatoFecha(item.fechaCreacion, 3) }}
        </template>
     
        <template #[`item.accion`]="{ item }">

          <BtnPdf :id="item.id" :tipoBtn="2"></BtnPdf>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-1" v-bind="attrs" v-on="on" @click="GetVer(item)">
                <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
              </a>
            </template>
            <span>Ver</span>
          </v-tooltip>

          
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon  color="error" :disabled="(item.idEstatus == 30 || store.state.sucursalSelect.id != item.idSucursalOrigen)" class="pr-1 " v-bind="attrs" v-on="on" @click="GetAnular(item)">
                <v-icon  >{{ icons.mdiCancel }}</v-icon>
              </v-btn>
            </template>
            <span>Anular</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <ModalViewTranslado
      @GetAnular="GetAnular"
      @GetEditar="GetEditar"
      @GetPdf="GetPdf"
      @GetFinalizar="GetFinalizar"
      @GetEnviar="GetEnviar"
      @GetRecibir="GetRecibir"
      ref="ModalViewTransladoRef"
    ></ModalViewTranslado>
  </div>
</template>
   
<script>
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiPhoneOutline,
  mdiEmail,
  mdiWeb,
  mdiEye,
  mdiFilePdfBox,
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import TransladoServices from '@/api/servicios/TransladoServices'
import Pdf from '@/components/Pdf.vue'
import FuncionesGenerales from '@/funciones/funciones'
import Vue from 'vue'
import ModalViewTranslado from './ModalViewTranslado.vue'
 
import BtnPdf from './BtnPdf.vue'
export default {
  watch: {
    '$store.state.sucursalSelect.id': function () {
      this.cargar()
    },
  },
  components: {
    Pdf,
    ModalViewTranslado,
    BtnPdf
  },
  setup(props, context) {
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const ModalViewTransladoRef = ref(null)
    const pdfRef = ref(null)

    const GetEditar = item => {
      if (item.idEstatus == 18) {
        console.log(item)
        context.emit('GetEditar', item)
      }
    }
    const anulando = ref(false)
    const GetAnular = item => {
       // //alert("GetAnular")
      if (item.idEstatus != 13) {
        anulando.value = true
        Vue.swal({
          icon: 'warning',
          html: '<h3>¿Seguro que quiere anular el ajuste? </h3>' + `<p>${item.numeroDocumento}</p>`,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return TransladoServices.Anular(store.state.user.idUsuario, { id: item.id })
              .then(response => {
                if (response.data.estatus == true) {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}: ${response.data.datos.mensajeError}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          elimiandoPago.value = false
        })
      }
    }
    const GetRecibir = item => {
       // //alert("GetRecibir")
      if (item.idEstatus == 20 && store.state.sucursalSelect.id == item.idSucursalDestino) {
        anulando.value = true
        Vue.swal({
          icon: 'warning',
          html: '<h3>¿Seguro que quiere recibir el translado? </h3>' + `<p>${item.numeroDocumento}</p>`,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return TransladoServices.Recibir(store.state.user.idUsuario, { id: item.id })
              .then(response => {
                if (response.data.estatus == true) {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}: ${response.data.datos.mensajeError}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          elimiandoPago.value = false
        })
      }
    }
    const GetEnviar = item => {
     //   //alert("GetEnviar")
      if ((item.idEstatus == 18 || item.idEstatus == 19) && store.state.sucursalSelect.id == item.idSucursalOrigen) {
        anulando.value = true
        Vue.swal({
          icon: 'warning',
          html: '<h3>¿Seguro que quiere enviar el translado? </h3>' + `<p>${item.numeroDocumento}</p>`,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return TransladoServices.Enviar(store.state.user.idUsuario, { id: item.id })
              .then(response => {
                if (response.data.estatus == true) {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}: ${response.data.datos.mensajeError}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          elimiandoPago.value = false
        })
      }
    }

    const GetFinalizar = item => {
        ////alert("GetFinalizar")
      if (item.idEstatus == 30 || store.state.sucursalSelect.id == item.idSucursalDestino) {
        Vue.swal({
          icon: 'warning',
          html: '<h3>¿Seguro que quiere finalizar la translado? </h3>' + `<p>${item.numeroDocumento}</p>`,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return TransladoServices.Finalizar(store.state.user.idUsuario, { id: item.id })
              .then(response => {
                if (response.data.estatus == true) {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}: ${response.data.datos.mensajeError}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          elimiandoPago.value = false
        })
      }
    }
    onBeforeMount(() => {
      cargar()
    })
    const GetVer = item => {
      ModalViewTransladoRef.value.abrir(item.id)
    }

    const cargar = () => {
      try {
        TransladoServices.Consultar({ idSucursal: store.state.sucursalSelect.id })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              Lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        cargandoDatos.value = false
      }
    }
    const CambiarIndActivo = item => {
      console.log('aqui')
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiPhoneOutline,
        mdiEmail,
        mdiWeb,
        mdiEye,
        mdiFilePdfBox,
      },
      search,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Area Origen', value: 'areaOrigen.nombre' },
        { text: 'Area Destino', value: 'areaDestino.nombre' },
        { text: 'Estatus', value: 'estatus.descripcion' },
        { text: 'fechaTranslado', value: 'fechaTranslado' },
        { text: 'fechaCreacion', value: 'fechaCreacion' },
        { text: 'NumeroDocumento', value: 'numeroDocumento' },
        { text: 'Accion', value: 'accion', sortable: false },
      ],
      Lista,
      ListaLoad,
      GetEditar,
      cargar,
      config,
      cargandoDatos,
      CambiarIndActivo,
      GetAnular,
      ModalViewTransladoRef,
      GetVer,
      GetFinalizar,
      GetRecibir,
      GetEnviar,
      FuncionesGenerales,
      store
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  