import Axios from 'axios';
import store from '@/store'
import http from ".."; 
import router from '@/router'

class SucursalServices {
    Consultar(datos) {
        return http.get("Sucursal/consultar", { params: datos })
        .catch((error) => {
            if (error.response.status == 401) {
                store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                router.push("/pages/login");
            }
        });
    }

    ConsultarXModulo(datos) {
        return http.get("Sucursal/consultarxModulo", { params: datos })
        .catch((error) => {
            if (error.response.status == 401) {
                store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                router.push("/pages/login");
            }
        });
    }

    Actualizar(
        IdUsuario,
        Data
    ) {
        const datos = {
            IdUsuario,
            Data
        }
        console.log(datos)
        return http.post("/sucursal/actualizar", datos)
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
}

export default new SucursalServices();