<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card v-if="dialog">
                <v-card-title>
                    <div class="d-flex align-center">
                        <v-avatar color="primary" :class="'v-avatar-light-bg white--text'" size="32">
                            <span>PR</span>
                        </v-avatar>
                        <div class="d-flex flex-column ms-3">
                            <span class="d-block font-weight-semibold text--primary text-truncate">
                                {{ FuncionesGenerales.camelCase(datos.producto.producto.nombre) }}
                            </span>
                            <small v-if="datos.idLote"> {{
                                FuncionesGenerales.camelCase(datos.producto.lote.numeroLote)
                            }} </small>
                        </div>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="form">
                            <v-row>

                              
                                <v-col cols="12"   >
                                    <v-text-field hide-details="auto" dense autofocus label="Cantidad" outlined
                                        v-model="cantidad" :rules="reglas.cantidad"
                                         required
                                        @keydown.enter="modificar()"></v-text-field>

                                </v-col>
                              
                                <v-col cols="12">
                                    <v-simple-table fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                     
                                                    <th class="text-left">
                                                        Existencia Actual
                                                    </th>
                                                    <th class="text-left">
                                                        Cantidad
                                                    </th> 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr> 
                                                    <th>{{ datos.existenciaActualOrigen }}</th>
                                                    <th>{{ Number(cantidad).toFixed(2) }}</th> 
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secundary" @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" @click="eliminar()">
                        Borrar
                    </v-btn>
                    <v-btn color="primary" @click="modificar()">
                        Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import store from '@/store'
export default {
    components: {
    },
    setup(props, context) {
        const dialog = ref(false)
        const datos = ref({})
        const cantidad = ref(0) 
        const abrir = (dato) => {
          
            dialog.value = true
            datos.value = dato
            console.log(datos.value)
            cantidad.value = datos.value.cantidad.toString() 
        }
       
        const reglas = {
            
            cantidad: [
                v => !!v || "Es requerido",
                v => /^-?\d*(\.\d+)?$/.test(v) || "No es un valor valido",
            ]
           
        };
        const form = ref(null)

        const validarForm = () => {
            let val = form.value?.validate()
            return val
        }
        const modificar = () => {
            if (validarForm()) {

                datos.value.cantidad = Number(cantidad.value)  
                dialog.value = false

            } else {
                store.commit('setAlert', {
                    message: "Verifique los datos",
                    type: 'info',
                })
            }


        }
        const eliminar = () => {

        }
        return {
            FuncionesGenerales,
            store,
            dialog,
            abrir,
            datos,
            modificar,
            eliminar, 
            cantidad,
            form,
            reglas 
        }
    }
}
</script>