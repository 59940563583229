//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class TransladoServices {


  Actualizar(
    IdUsuario,
    Data
  ) {
    const datos = {
      IdUsuario,
      Data
    }
    console.log(datos)
    return http.post("/Translado/actualizar", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  Consultar(datos) {
    return http.get("Translado/consultar", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  ConsultarDetalles(datos) {
    return http.get("Translado/consultar/detalle", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }


  Anular(
    IdUsuario,
    Data
  ) {
    const datos = {
      IdUsuario,
      Data
    }
    console.log(datos)
    return http.post("/Translado/anular", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  Recibir(
    IdUsuario,
    Data
  ) {
    const datos = {
      IdUsuario,
      Data
    }
    console.log(datos)
    return http.post("/Translado/recibir", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  Enviar(
    IdUsuario,
    Data
  ) {
    const datos = {
      IdUsuario,
      Data
    }
    console.log(datos)
    return http.post("/Translado/enviar", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
  Finalizar(
    IdUsuario,
    Data
  ) {
    const datos = {
      IdUsuario,
      Data
    }
    console.log(datos)
    return http.post("/Translado/finalizar", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
  ConsultarTransladoDetalleReporte(datos) {
    return http.get("Translado/detalles/consultar/reporte", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
}


export default new TransladoServices();