<template>
  <row>
    <v-btn v-if="tipoBtn == 1" color="info" small @click="GetPdf()" :loading="cargandoDatos" :disabled="!id">
      <v-icon left small>
        {{ icons.mdiFilePdfBox }}
      </v-icon>
      PDF
    </v-btn>

    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="primary" v-bind="attrs" :loading="cargandoDatos" v-on="on" @click="GetPdf()">
          <v-icon>{{ icons.mdiFilePdfBox }}</v-icon>
        </v-btn>
      </template>
      <span>PDF</span>
    </v-tooltip>
    <Pdf ref="pdfRef"></Pdf>
  </row>
</template>
<script>
import { mdiFilePdfBox } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from '@/components/Pdf.vue'
import TransladoServices from '@/api/servicios/TransladoServices'
import store from '@/store'
export default {
  props: {
    id: Number,
    tipoBtn: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Pdf,
  },
  setup(props) {
    const cargandoDatos = ref(false)
    const pdfRef = ref(null)
    const GetPdf = () => {
      cargandoDatos.value = true

      TransladoServices.ConsultarDetalles({ id: props.id })
        .then(response => {
          console.log(response)
          if (response.data.estatus == true) {
            const datoInit = response.data.datos[0]
            const cuerpoTablaDetalles = []
            const cuerpoTablaHistorial = []

            datoInit.transladoDetalle.forEach(item => {
              cuerpoTablaDetalles.push([
                { content: item.producto.barra, styles: { halign: 'left' } },
                { content: item.producto.nombre, styles: { halign: 'left' } },
                { content: item.lote ? item.lote.numeroLote : '', styles: { halign: 'left' } },
                { content: item.cantidad.toFixed(2), styles: { halign: 'right' } },
              ])
            })
            datoInit.transladoEstatus.forEach(item => {
              cuerpoTablaHistorial.push([
                { content: FuncionesGenerales.formatoFecha(item.fecha, 3), styles: { halign: 'left' } },
                { content: item.estatus.descripcion, styles: { halign: 'left' } },
                {
                  content: item.usuario.empleado.nombre + ' ' + item.usuario.empleado.nombre,
                  styles: { halign: 'left' },
                },
              ])
            })

            const datosInit = {
              nombreDocumento: `Translado ${datoInit.numeroDocumento}`,

              cabecera1: {
                visible: true,
              },
              titulo: {
                visible: true,
                posicion: 'center',
                texto: 'Translado ',
                TamanoLetras: 14,
                color: '#7B7B7B',
              },
              cabecera2: {
                visible: true,
                datos: [
                  { texto: '# ' + datoInit.numeroDocumento, TamanoLetras: 14, color: '#FF0000' },
                  { texto: 'Fecha Creacion: ' + FuncionesGenerales.formatoFecha(datoInit.fechaCreacion, 1) },
                  {
                    texto:
                      'Hora Creacion: ' +
                      FuncionesGenerales.formatoFecha(datoInit.fechaCreacion, 2)
                        .replace(/\u00a0/g, '')
                        .replace(/\u202f/g, ''),
                  },
                  { texto: 'Exportado: ' + store.state.usuario.nombre + ' ' + store.state.usuario.apellido },
                ],
              },
              sub1: {
                visible: true,
                datos: [
                  { texto: 'Sucursal Origen: ' + datoInit.sucursalOrigen.nombre },
                  { texto: 'Sucursal Destino: ' + datoInit.sucursalDestino.nombre },
                  { texto: 'Estatus:' + datoInit.estatus.descripcion },
                ],
              },
              sub2: {
                visible: true,
                datos: [
                  { texto: 'Fecha Translado: ' + FuncionesGenerales.formatoFecha(datoInit.fechaTranslado, 1) },
                  { texto: 'Area Origen: ' + datoInit.areaOrigen.nombre },
                  { texto: 'Area Destino: ' + datoInit.areaDestino.nombre },
                ],
              },
              cuerpo1: {
                visible: true,
                datos: [
                  { texto: 'Observación: ' + datoInit.observacion },
                 
                  { linea: true },
                  { texto: ' ' },
                  {
                    titulo: { texto: 'Detalles', TamanoLetras: 12 },
                    styles:   {fontSize: 7,},
                    columnStyles: {
                      3: {
                        halign: 'right',
                      },
                    },
                    tablas: {},
                    head: [
                      [
                        { content: 'Barra', styles: { halign: 'left' } },
                        { content: 'Producto', styles: { halign: 'left' } },
                        { content: 'Lote', styles: { halign: 'left' } },
                        { content: 'Cant', styles: { halign: 'right' } },
                      ],
                    ],
                    body: cuerpoTablaDetalles,
                  },

                  {
                    titulo: { texto: 'Historial', TamanoLetras: 12 },
                    styles:   {fontSize: 7,},
                    columnStyles: {
                      3: {
                        halign: 'right',
                      },
                    },
                    tablas: {},
                    head: [
                      [
                        { content: 'Fecha', styles: { halign: 'left' } },
                        { content: 'Estatus', styles: { halign: 'left' } },
                        { content: 'Empleado', styles: { halign: 'left' } },
                      ],
                    ],
                    body: cuerpoTablaHistorial,
                  },
                ],
              },
            }

            pdfRef.value.generarPDF(datosInit)
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargandoDatos.value = false
        })
    }

    return {
      GetPdf,
      cargandoDatos,
      pdfRef,
      icons: {
        mdiFilePdfBox,
      },
    }
  },
}
</script>