<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="400px">
            <v-card>
                <v-card-text>
                    Selecciona el estatus del translado 
                </v-card-text>
                <v-card-text>
                    <v-item-group mandatory v-model="select">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6" v-for="(item, i) in estatus" :key="i">
                                    <v-item>
                                        <v-card :color="select == i ? 'info' : 'secondary'"
                                            class="d-flex align-center" height="100" @click="seleccionar(i)">
                                            <v-scroll-y-transition>
                                                <div class=" text-h5 flex-grow-1 text-center"
                                                    :class="select == i ? 'white--text ' : 'white--text'">
                                                    {{ item.descripcion }}
                                                </div>
                                            </v-scroll-y-transition>
                                        </v-card>
                                    </v-item>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-item-group>
                    <v-divider></v-divider>
                </v-card-text>
               
                <v-card-actions class="justify-end">
                    <v-btn small @click="dialog = false" color="secondary">Cerrar</v-btn>
                    <v-btn small @click="aceptar" color="primary">Aceptar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { ref } from '@vue/composition-api';

export default {
    props: {
        estatus: Array
    },
    setup(props, context) {
        const dialog = ref(false)
        const select = ref(0)

        const seleccionar = (item) => {
            select.value = item
        }
        const aceptar = () => {
            const valor = props.estatus.filter((item, i) => i == select.value)[0]
            context.emit("aceptar", valor)
            dialog.value = false
        }
        const abrir = () => {

            dialog.value = true
        }
        return {
            dialog,
            select,
            abrir,
            seleccionar,
            aceptar
        }
    },
}
</script>
